/*
 * File: general.js
 * Project: our-wave-admin-app
 *
 * Created by Brendan Michaelsen on December 31, 2021 at 2:14 PM
 * Copyright © 2021 - 2024 Our Wave, Inc. All rights reserved.
 *
 * Last Modified: March 27, 2025 at 10:13 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Requires
 */

// Themes
require('foundation-sites/dist/css/foundation.css');
require('../../css/app.css');

// Modules
window.Parse = require('parse');
const jQuery = require('jquery');
require('foundation-sites');

// Utilities
const { logoutUser } = require('./auth');
const { getCookie, getAllUrlParams } = require('./utilities');
const { handleAuthActions } = require('./user');

// Constants
const {
	USER_IMPERSONATION_COOKIE,
	USER_IMPERSONATION_HEADER,
	ROLES
} = require('../../../constants/general.constants');

// Set Globals
window.$ = jQuery;
window.jQuery = jQuery;


/**
 * State
 */

let globalState = null;


/**
 * Handlers
 */

const handleImpersonationState = () => {

	// Get token
	const token = getCookie(USER_IMPERSONATION_COOKIE);
	if (token != null && token !== '') {

		// Set core manager header
		const headerObj = {};
		headerObj[USER_IMPERSONATION_HEADER] = token;
		Parse.CoreManager.set('REQUEST_HEADERS', headerObj);
	}
};

// Remove hash from URL
$(document).ready(async () => {

	// Handle impersonator token
	if (window.location.search.indexOf('imptoken=') > -1) {

		// Get token
		const { imptoken } = getAllUrlParams();

		// Set state cookie
		const cookieDomain = (process.env.ENV === 'development') ? '' : 'domain=ourwave.org; ';
		document.cookie = `${USER_IMPERSONATION_COOKIE}=${imptoken}; path=/; ${cookieDomain}SameSite=None; Secure`;

		// Update state
		handleImpersonationState();

		// Logout user
		try { await Parse.Cloud.run('logoutUser', null); } catch (err) {}
		try { await Parse.User.logOut(); } catch (err) {}

		// Fetch user state
		const { sessionToken } = await Parse.Cloud.run('validateImpersonationIntent');

		// Become user
		await Parse.User.become(sessionToken);

		// Handle auth actions
		const userObj = Parse.User.current();
		handleAuthActions(userObj);

		// Check role permissions
		const isAnalyticsOnly = userObj.get('roles').includes(ROLES.ANALYTICS_ONLY);

		// Move to dashboard
		window.location = isAnalyticsOnly ? '/dashboard' : '/dashboard/review';
	}

	// Remove top hash from URL
	const urlString = window.location.toString();
	if (urlString.indexOf('#') !== -1) {
		const newurlString = urlString.split('#')[0];
		if (urlString.split('#')[1] === 'top' || urlString.split('#')[1] === '') {
			window.history.pushState(null, '', newurlString);
		}
	}
});

// Enable smooth scroll
const $root = $('html, body');
$(document).on('click', 'a', function () {
	if ($(this).attr('href') && $(this).attr('href').match('^/#')) {
		if (window.location.pathname === '/') {
			$root.animate({
				scrollTop: $($(this).attr('href').substring(1)).offset().top
			}, 500);
		} else {
			window.location = $(this).attr('href');
		}
		return false;
	} if ($(this).attr('href') && $(this).attr('href').indexOf('#') > -1) {
		const tag = $(this).attr('href').split('#').pop();
		if (tag !== '') {
			$root.animate({
				scrollTop: $(`#${tag}`).offset().top
			}, 500);
			return false;
		}
	}
	return undefined;
});

// Handle get component state
const getComponentState = () => globalState;


/**
 * Init
 */

// Initialize Foundation
$(document).foundation();

// Activate post-load features
$(document).ready(() => {
	$('body').removeClass('preload');
});

// Get global data
try {
	globalState = JSON.parse(JSON.stringify($('#data-source').data('source')));
	setTimeout(() => {
		$('#data-source').remove();
	}, 1000);
} catch (e) {}

// Initialize Parse
Parse.initialize(process.env.APP_ID);
Parse.serverURL = process.env.SERVER_URL;

// Check current authentication status
if (globalState != null && globalState.user.active_session !== true && Parse.User.current() != null) {
	logoutUser(false, false);
}


/**
 * Exports
 */

module.exports = {
	getComponentState
};
